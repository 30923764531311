import isServer from 'commons/isServer';
import getEnvValue from 'commons/getEnvValue';
import isNewConsumerApp from './isNewConsumerApp';

const getAlexandriaUrl = () => {
  if (isNewConsumerApp) {
    return isServer() && process.env.PRIVATE_GRAPHQL_BASE_URL
      ? process.env.PRIVATE_GRAPHQL_BASE_URL
      : getEnvValue('NEXT_PUBLIC_GRAPHQL_BASE_URL');
  }

  return isServer()
    ? getEnvValue('PRIVATE_GRAPHQL_BASE_URL')
    : getEnvValue('REACT_APP_GRAPHQL_BASE_URL');
};

export default getAlexandriaUrl;
