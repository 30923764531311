import isServer from './isServer';
import getEnvValue from './getEnvValue';
import getCookies from './getCookies';

const getStoreDetails = () => {
  if (isServer()) {
    return null;
  }
  const APP_VERSION =
    getEnvValue('NEXT_PUBLIC_VERSION') ??
    getEnvValue('REACT_APP_VERSION') ??
    '';
  const storeHostName =
    (APP_VERSION !== 'production' && getCookies().get('storeHostName')) ||
    window.location.hostname;

  switch (storeHostName) {
    case 'bk.joybird.com':
      return {
        id: '1',
        name: 'Brooklyn',
        zipCode: '11201',
      };
    case 'dc.joybird.com':
      return {
        id: '2',
        name: 'Washington',
        zipCode: '20005',
      };
    case 'chi.joybird.com':
      return {
        id: '4',
        name: 'Chicago',
        zipCode: '60610',
      };
    case 'weho.joybird.com':
      return {
        id: '6',
        name: 'West Hollywood',
        zipCode: '90069',
      };
    case 'sf.joybird.com':
      return {
        id: '7',
        name: 'San Francisco',
        zipCode: '94102',
      };
    case 'atx.joybird.com':
      return {
        id: '8',
        name: 'Austin',
        zipCode: '78758',
      };
    case 'sea.joybird.com':
      return {
        id: '9',
        name: 'Seattle',
        zipCode: '98122',
      };
    case 'soho.joybird.com':
      return {
        id: '10',
        name: 'Manhattan',
        zipCode: '10012',
      };
    case 'phil.joybird.com':
      return {
        id: '11',
        name: 'Philadelphia',
        zipCode: '19102',
      };
    case 'la.joybird.com':
      return {
        id: '12',
        name: 'Downtown Los Angeles',
        zipCode: '90021',
      };
    case 'den.joybird.com':
      return {
        id: '13',
        name: 'Denver',
        zipCode: '80206',
      };
    case 'vs.joybird.com':
      return {
        id: '5',
        name: 'Virtual Showroom',
        zipCode: '*',
      };
    case 'pdx.joybird.com':
      return {
        id: '14',
        name: 'Portland',
        zipCode: '97209',
      };
    case 'cs.joybird.com':
      return {
        id: '15',
        name: 'Customer Service',
        zipCode: '0',
      };
    case 'nis.joybird.com':
      return {
        id: '3',
        name: 'Nis',
        zipCode: '-1',
      };
    case 'lzbmia.joybird.com':
      return {
        id: '17',
        name: 'LZB Miami',
        zipCode: '-1',
      };
    default:
      return null;
  }
};

export default getStoreDetails;
