'use client';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { debounce } from 'lodash';

const useSearchUrlQueryParams = () => {
  const history = useHistory();
  const {
    q: queryUrlParam = '',
    category: categoryUrlParam = 'products_jb4',
  } = queryString.parse(history?.location?.search ?? '');

  const updateUrlParam = useMemo(
    () =>
      debounce(
        updates => {
          if (updates) {
            const initialUrlParams = queryString.parse(
              history?.location?.search ?? ''
            );

            const updatedUrlParams = queryString.stringify({
              ...initialUrlParams,
              category: initialUrlParams?.category ?? 'products_jb4',
              filter: initialUrlParams?.filter ?? '*',
              ...updates,
            });

            history.replace?.(
              `${history.location.pathname}${
                !history.location.pathname.endsWith('/') ? '/' : ''
              }?${updatedUrlParams}`
            );
          }
        },
        [1000]
      ),
    [history]
  );

  return {
    queryUrlParam,
    categoryUrlParam,
    updateUrlParam,
  };
};

export default useSearchUrlQueryParams;
