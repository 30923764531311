/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useRef, useEffect } from 'react';
import { useDebounce } from 'react-use';
import classNames from 'classnames';
import {
  connectInfiniteHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import avo from 'analytics/avo';

import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import ProductListingPage from 'components/consumer/ProductListingPage';
import ProductsGridStatic from 'components/consumer/products-grid/ProductsGridStatic';
import ProductsGridLoading from 'components/consumer/products-grid/components/ProductsGridLoading';
import useStaticProductListingPageData from 'hooks/useStaticProductListingPageData';
import useProductVariantsToShow from 'hooks/useProductListingData/useProductVariantsToShow';
import useSearchUrlQueryParams from 'components/consumer/consumer-search/advanced-search/hooks/useSearchUrlQueryParams';
import SeeFullResultsButton from '../SeeFullResultsButton';

export const productHitsOptionColorMatch = productHits => {
  if (!productHits?.length) {
    return [];
  }

  const firstHit = productHits[0];

  if (firstHit?._highlightResult?.option_color?.length > 0) {
    const { option_color } = firstHit._highlightResult;
    const optionColorEntries = firstHit.option_color || [];

    return option_color.reduce((matched, color, index) => {
      if (color?.matchedWords?.length) {
        if (
          (optionColorEntries[index] || '').toLowerCase().trim() ===
          color.matchedWords[0].toLowerCase().trim()
        ) {
          return [...matched, optionColorEntries[index].toLowerCase().trim()];
        }
      }

      return matched;
    }, []);
  }

  return [];
};

const StaticProductResultsBase = connectStateResults(
  ({
    displayPagesResultComponent,
    forComponent,
    hasMore,
    hits: algoliaHits,
    isInstantSearch,
    layout,
    loadingMore,
    loadRest,
    optionState,
    pageHeaderOverride,
    searching,
    searchResults,
    searchState,
    searchStateQuery,
    setLoading,
    sidebarHeader,
    toggleSearchOpen,
    variant = 'desktop',
  }) => {
    const searchLoading =
      (isInstantSearch && !searchResults && !searchResults.hits) ||
      (!isInstantSearch && !algoliaHits && !searchResults);

    const needsToLoadMore = !!(
      searchResults?.nbHits &&
      searchResults.nbHits !== searchResults?.hits?.length
    );

    // when its advanced search, swap out the full results when the full results are loadded
    const hits = isInstantSearch
      ? searchResults.hits
      : !needsToLoadMore
      ? searchResults?.hits
      : algoliaHits;

    const gridPlaceholderItemContainerRef = useRef();

    useEffect(() => {
      // conditional display of page results
      if (!hits?.length && isInstantSearch && displayPagesResultComponent) {
        displayPagesResultComponent(true);
      }
      if (isInstantSearch && displayPagesResultComponent) {
        displayPagesResultComponent(false);
      }
    }, [hits, isInstantSearch, displayPagesResultComponent]);

    const colorFacetQuery = productHitsOptionColorMatch(hits);

    const fullLoadStatus = useRef('not-initiated'); // not-initiated | initiated | loaded | error

    const productIDs = useMemo(
      () => hits?.map(hit => parseInt(hit.product_id, 10)) ?? [],
      [hits]
    );

    const instantSearchStaticData = useStaticProductListingPageData(
      {
        productIds: productIDs,
        showSearchProductsOnly: true,
      },
      {
        onlyShowValidFilters: true,
      }
    );

    const productsWithColoredVariants = useProductVariantsToShow({
      colorFilters: colorFacetQuery?.length > 0 ? colorFacetQuery : null,
      products: instantSearchStaticData.products,
    });

    const instantSearchProductsData = useMemo(
      () =>
        colorFacetQuery?.length > 0
          ? productsWithColoredVariants.slice(0, 3)
          : instantSearchStaticData?.products?.slice(0, 3) ?? [],
      [colorFacetQuery, productsWithColoredVariants, instantSearchStaticData]
    );

    const hasMoreProducts = useMemo(
      () =>
        instantSearchProductsData?.length >
        instantSearchStaticData?.products?.length
          ? hasMore
          : false,
      [instantSearchProductsData, instantSearchStaticData, hasMore]
    );

    const { queryUrlParam: query, updateUrlParam } = useSearchUrlQueryParams();

    useDebounce(
      () => {
        if (searchState.query !== query) {
          updateUrlParam({ q: searchState.query });
        }
      },
      1000, // adjusted to resolve query call and re-render race condition
      [searchState.query]
    );

    useEffect(() => {
      if (isInstantSearch) {
        return;
      }

      // If loading of all products has not yet started, load all products when
      // the browser is idle
      if (needsToLoadMore && fullLoadStatus.current === 'not-initiated') {
        fullLoadStatus.current = 'initiated';
        window.requestIdleCallback(() => {
          loadRest();
        });
      } else if (!needsToLoadMore && fullLoadStatus.current === 'initiated') {
        fullLoadStatus.current = 'loaded';
      }
    }, [needsToLoadMore, loadRest, isInstantSearch, fullLoadStatus]);

    const currentPage = useUserCurrentPageType();

    const searchPageData = useMemo(() => {
      if (isInstantSearch) {
        return null;
      }

      const pageData = {
        ...instantSearchStaticData,
        filters:
          // Remove Search filter from filters array
          instantSearchStaticData?.filters?.filter(
            filterItem => filterItem.id !== 'search'
          ) ?? [],
        pageName: `Results for "${searchStateQuery ?? ''}"`,
      };

      if (searchLoading) {
        return {
          ...pageData,
          loading: true,
        };
      }

      return pageData;
    }, [
      instantSearchStaticData,
      isInstantSearch,
      searchLoading,
      searchStateQuery,
    ]);

    return (
      <div
        className={classNames(
          'instant-search-form__product-results flex-1 pt-5 pb-10 [&_.ais-Hits-list]:flex [&_.ais-Hits-item]:p-2.5 [&_.ais-Hits-list]:[list-style:none] max-[1023px]:[&_.ais-SearchBox-input]:pl-4',
          {
            'pr-8': isInstantSearch,
          }
        )}
      >
        {forComponent === 'mobile' && !searchLoading && (
          <div
            className={classNames('mx-0 my-2.5 font-bold text-sm', {
              'hidden lg:block': variant === 'advanced-search',
              'text-gray-light2': variant !== 'mobile',
              'text-brand block mt-[15px] mb-[7px]': variant === 'mobile',
            })}
          >
            Results
          </div>
        )}

        <div
          onClick={() => {
            if (searchState?.query !== '') {
              avo.productsSearched({
                query: searchState.query,
                location: currentPage,
                searchTarget: avo.SearchTarget.PRODUCT_GRID,
              });
            }
          }}
        >
          {variant === 'advanced-search' && (
            <ProductListingPage
              pageData={searchPageData}
              pageHeaderOverride={pageHeaderOverride}
              sidebarHeader={sidebarHeader}
              variant="advanced-search"
            />
          )}

          {isInstantSearch &&
            !searchLoading &&
            instantSearchProductsData?.length > 0 && (
              <ProductsGridStatic
                columnsOnDesktop="3"
                columnsOnMobile="2"
                getStockData
                hasMoreProducts={hasMoreProducts}
                layout={layout || layout}
                listName="Search"
                loading={searching}
                loadingMore={loadingMore}
                maxCardsCount={3}
                optionState={optionState}
                productsData={instantSearchProductsData}
                setLoading={setLoading}
              />
            )}

          {isInstantSearch && searchLoading && (
            <ProductsGridLoading
              cardsCount={3}
              columnsOnDesktop="3"
              columnsOnMobile="2"
              itemContainerRef={gridPlaceholderItemContainerRef}
            />
          )}
        </div>

        {isInstantSearch &&
          !searchLoading &&
          instantSearchStaticData?.products?.length > 0 && (
            <div
              className={classNames('flex mt-5 [&_a]:[text-decoration:none]', {
                'justify-start': variant === 'mobile',
                'justify-center': variant === 'desktop',
              })}
            >
              <SeeFullResultsButton
                toggleSearchOpen={toggleSearchOpen}
                colorFilters={colorFacetQuery}
              />
            </div>
          )}
      </div>
    );
  }
);

const StaticProductResults = connectInfiniteHits(StaticProductResultsBase);

export default StaticProductResults;
