import sha256 from 'crypto-js/sha256';
import utf8 from 'utf8';
import getStoreDetails from 'commons/getStoreDetails';
import getClientIPExperimental from 'commons/getClientIPExperimental';
import { countryCodeByName } from './country';
import getCookies from './getCookies';
import isServer from './isServer';
import { getItemFromStorage } from './localStorage';
import { FB_LOGIN_PII } from './constants';
import getEnvValue from './getEnvValue';

// fbc cookie
export const getFBClickId = () =>
  isServer() ? null : getCookies().get('_fbc');

// fbp cookie Browser ID
export const getFBBrowserId = () =>
  isServer() ? null : getCookies().get('_fbp');

export function getUserFBPii(unhashed = false) {
  const userPii = getItemFromStorage(FB_LOGIN_PII);

  if (userPii) {
    const serializedData = {};
    if (userPii.email && userPii.email !== '') {
      serializedData.em = unhashed
        ? userPii.email
        : sha256(userPii.email).toString();
    }
    if (userPii.name && userPii.name !== '') {
      const [firstName, lastName] = userPii.name.split(' ');

      const fnRaw = firstName || userPii.name;
      const lnRaw = lastName || userPii.name;

      serializedData.fn = unhashed ? fnRaw : sha256(fnRaw).toString();
      serializedData.ln = unhashed ? lnRaw : sha256(lnRaw).toString();
    }
    if (userPii.id) {
      serializedData.fb_login_id = userPii.id;
    }

    return serializedData;
  }

  return {};
}

export function identifyFBUser(userDetails) {
  if (isServer()) {
    return;
  }

  const eventData = {
    ...userDetails,
    client_user_agent: navigator.userAgent,
    client_ip_address: getClientIPExperimental(),
    fbc: getFBClickId(),
    fbp: getFBBrowserId(),
    country: userDetails?.country
      ? countryCodeByName(userDetails?.country)
      : null,
    ...getUserFBPii(true),
  };

  Object.entries(eventData).forEach(([key, value]) => {
    if (!value) delete eventData[key];
    else eventData[key] = utf8.encode(value || value.trim());
  });

  if (eventData?.fn)
    eventData.fn = sha256(eventData.fn.toLowerCase()).toString();
  if (eventData?.ln)
    eventData.ln = sha256(eventData.ln.toLowerCase()).toString();
  if (eventData?.em)
    eventData.em = sha256(eventData.em.toLowerCase()).toString();

  if (eventData?.ph) {
    eventData.ph = eventData?.ph.replace(/[^0-9]/g, '');
    eventData.ph = sha256(`1${eventData?.ph}`).toString(); // USA only
  }

  if (eventData?.ct)
    eventData.ct = sha256(
      eventData.ct.toLowerCase().replace(/[^a-z0-9]/g, '')
    ).toString();
  if (eventData?.st)
    eventData.st = sha256(
      eventData.st?.toLowerCase().replace(/[^a-z0-9]/g, '')
    ).toString();

  if (eventData?.zip) eventData.zip = sha256(eventData.zip).toString();
  if (eventData?.country)
    eventData.country = sha256(eventData.st?.toLowerCase().country).toString();

  window?.fbq?.(
    'init',
    getEnvValue('NEXT_PUBLIC_FB_PIXEL_ID') ??
      getEnvValue('REACT_APP_FB_PIXEL_ID') ??
      '',
    userDetails
  );
}

const formatLocationData = data =>
  typeof data === 'string'
    ? sha256(data.replace(/[^a-zA-Z]|\s+/g, '').toLowerCase()).toString()
    : '';

const storeData = getStoreDetails();
export async function sendFBEvent(event, data) {
  if (isServer()) {
    return;
  }
  let eventId = {};

  const trackingData = {
    ...data,
    action_source: storeData?.id ? 'physical_store' : 'website',
    client_user_agent: navigator.userAgent,
    event_source_url: window.location.toString(),
    fbc: getFBClickId(),
    fbp: getFBBrowserId(),
    ...getUserFBPii(),
    zp: formatLocationData(data?.zp),
    ct: formatLocationData(data?.ct),
    st: formatLocationData(data?.st),
  };

  if (trackingData?.eventID) {
    eventId = { eventID: data.eventID };
    delete trackingData.eventID;
  }

  window?.fbq?.('track', event, trackingData, eventId);
}

export async function sendCustomFBEvent(event, data) {
  if (isServer()) {
    return;
  }

  const trackingData = {
    ...data,
    action_source: storeData?.id ? 'physical_store' : 'website',
    client_user_agent: navigator.userAgent,
    event_source_url: window.location.toString(),
    fbc: getFBClickId(),
    fbp: getFBBrowserId(),
    ...getUserFBPii(),
    zp: formatLocationData(data?.zp),
    ct: formatLocationData(data?.ct),
    st: formatLocationData(data?.st),
  };

  window?.fbq?.('trackCustom', event, trackingData);
}

export const FB_EVENTS = {
  ADD_TO_CART: 'AddToCart',
  CHECKOUT: 'InitiateCheckout',
  PURCHASE: 'Purchase',
  VIEW_PDP: 'ViewContent',
  SELECT_VARIATION: 'SelectVariation',
  NEWSLETTER_SIGNUP: 'CompleteRegistration',
  LEAD: 'Lead',
};
